<template>
  <div>
    <label class="bg-white cursor-pointer flex-1 border-2 border-gray-100 booking-option-shadow flex rounded-xl" :class="{ 'border-pink-500': selected == option.id }">
      <div class="flex-shrink-0 flex justify-end py-6 pl-6">
        <img :src="option.icon_url" class="h-8 w-8 lg:h-14 lg:w-14" alt="">
      </div>

      <div class="flex-1 py-6 px-6">
        <h3 class="font-bold text-xl text-blue-800">{{ option.name }}</h3>
        <p class="text-sm text-gray-500 mt-2">
          If you are a blogger or contributor to a large publication, and you need experts to quote in your article, use this option for written responses to your questions.
        </p>
      </div>

      <div class="w-28 flex-shrink-0 border-l border-guestio-gray-50 flex items-center flex-col justify-center ">
        <span class="text-xl font-bold text-blue-800">${{ price.price }}</span>
        <span class="font-bold text-sm text-gray-500 mt-1">/question</span>
        <input type="radio" class="hidden" :value="option.id" v-model="selected">
      </div>
    </label>

    <div v-if="selected == option.id" class="my-4">
      <h4 class="font-bold text-lg text-blue-800">How many questions would you like to ask?</h4>

      <div class="flex items-center space-x-6 mt-3">
        <div class="selector bg-guestio-gray-200 p-2 rounded flex items-center">
          <button @click.prevent="questionsCount--" type="button" class="bg-white rounded flex items-center justify-center w-8 h-8 disabled:opacity-50 focus:shadow-outline hover:bg-gray-100" :disabled="questionsCount == 1">
            -
          </button>
          <span class="w-18 text-2xl font-bold text-blue-800 leading-none text-center">
            {{ questionsCount }}
          </span>
          <button @click.prevent="questionsCount++" type="button" class="bg-white rounded flex items-center justify-center w-8 h-8 focus:shadow-outline disabled:opacity-50 hover:bg-gray-100" :disabled="questionsCount == 20">
            +
          </button>
        </div>
        <div>
          <span>Total: </span>
          <span class="font-bold">${{ questionsCount * price.price }}</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import { first } from 'lodash/array'

  export default {
    props: {
      option: Object,
      value: Number,
    },

    data() {
      return {
        selected: this.value,
        questionsCount: 5,
      }
    },

    computed: {
      price() {
        return first(this.option.prices)
      }
    },

    watch: {
      value(value) {
        this.selected = value
      },

      selected(selected) {
        if (selected != this.option.id) {
          return
        }

        this.$emit('change', {
          option: selected,
          price: this.price,
          amount: this.questionsCount
        })
      },

      questionsCount(questionsCount) {
        this.$emit('change', {
          option: this.selected,
          price: this.price,
          amount: questionsCount
        })
      },
    },
  }
</script>
